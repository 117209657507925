<template>
  <div>
    <a-card>
      <template slot="title">
        <strong>Tabungan</strong>
        <!-- <a-range-picker class="ml-3" :format="'DD-MM-YYYY'" @change="rangedofunction"/> -->
        <!-- <a-button
          type="button"
          class="btn btn-outline-primary ml-2"
          @click="connector('nonanggota')"
        >
          Tambah Non Anggota
        </a-button> -->
        <a-button
          type="button"
          class="btn btn-outline-primary ml-4"
          @click="connector"
        >
          Tambah
        </a-button>
            <a-button
              class="btn btn-outline-success ml-2"
              @click="getAllData(true)"
              >Refresh Data</a-button
            >
      </template>
      <a-table
        :scroll="{ x: 1000}"
        :columns="columns"
        :dataSource="data"
        :pagination="{
          hideOnSinglePage:true,
          defaultPageSize: 10,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
          pageSizeOptions: ['10', '20', '30'],
        }"
        size="small"
      >
        <div
          slot="filterDropdown"
          slot-scope="{
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            column,
          }"
          style="padding: 8px;"
        >
          <a-input
            v-ant-ref="(c) => (searchInput = c)"
            :placeholder="`Search ${column.dataIndex}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block;"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="
              () => handleSearch(selectedKeys, confirm, column.dataIndex)
            "
          />
          <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px;"
            @click="
              () => handleSearch(selectedKeys, confirm, column.dataIndex)
            "
          >
            Search
          </a-button>
          <a-button
            size="small"
            style="width: 90px;"
            @click="() => handleReset(clearFilters)"
          >
            Reset
          </a-button>
        </div>
        <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#108ee9' : undefined }"
        ></a-icon>
        <template
          slot="customRender"
          slot-scope="text, record, index, column"
        >
          <span v-if="searchText && searchedColumn === column.dataIndex">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(
                  new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                )"
            >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
                >{{ fragment }}</mark
              >
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>
            {{ text }}
          </template>
        </template>
        <a slot="name" slot-scope="text" href="javascript:;">{{ text }}</a>
        <span slot="customTitle"><a-icon type="smile-o" /> Kode</span>
        <span slot="tags" slot-scope="tags">
          <a-tag v-for="tag in tags" color="blue" :key="tag">{{ tag }}</a-tag>
        </span>
        <span slot="action" slot-scope="text, record">
          <a class="text-warning" @click="sendToEdit(record)">
            <a-icon type="edit"></a-icon>
          </a>
          <a-divider type="vertical" />
          <a href="javascript:void(0)" class="text-danger" @click="showDeleteConfirm(record)">
            <a-icon type="delete"></a-icon>
          </a>
          <a-divider type="vertical" />
          <a href="javascript:void(0)" class="text-success" @click="printHeader(record)">
            <a-icon type="printer"></a-icon>
          </a>
          <!-- <a href="javascript:;" class="ant-dropdown-link">
            More actions <a-icon type="down" />
          </a> -->
        </span>
        <span slot="jenis_identitas" slot-scope="text, record">
          {{ findRelationData(record, 'masterJenisIndentitas', 'id', 'jenis_identitas', 'keterangan') }}
        </span>
        <span slot="tgl" slot-scope="text, record">
          {{ formatDate(record.tgl) }}
        </span>
      </a-table>
    </a-card>
    <mymodal ref="myownmodal" :key="componentKey" />
  </div>
</template>

<script>
/* Import Script */
import { Modal } from 'ant-design-vue'
import * as lou from '@/services/data/lou'
import moment from 'moment'
import { mapState } from 'vuex'
import mymodal from './form'
import JSPDF from 'jspdf'
/* End Script Import Script */

/* Kolom atau header dari table */
const columns = [
  {
    title: 'Action',
    scopedSlots: { customRender: 'action' },
    fixed: 'left',
    width: 100,
  },
  {
    title: 'Rekening',
    dataIndex: 'rekening',
    width: 100,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.rekening.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Nama',
    dataIndex: 'nama',
    width: 150,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.nama.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'No Seri',
    dataIndex: 'noseri',
    width: 80,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.noseri.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Tanggal',
    width: 100,
    dataIndex: 'tgl',
    scopedSlots: { customRender: 'tgl' },
    // width: 100,
  },
  {
    title: 'Golongan Tabungan',
    width: 100,
    dataIndex: 'golongantabungan',
    scopedSlots: { customRender: 'golongantabungan' },
    // width: 100,
  },
  {
    title: 'ZIS',
    width: 70,
    dataIndex: 'zis',
    scopedSlots: { customRender: 'zis' },
    // width: 100,
  },
  {
    title: 'AO',
    width: 100,
    dataIndex: 'nama_ao',
    scopedSlots: { customRender: 'ao' },
    // width: 100,
  },
  {
    title: 'Ahli Waris',
    width: 150,
    dataIndex: 'ahliwaris',
    scopedSlots: { customRender: 'ahliwaris' },
    // width: 100,
  },
  {
    title: 'Baris Akhir',
    width: 70,
    dataIndex: 'baristerakhir',
    scopedSlots: { customRender: 'baristerakhir' },
    // width: 100,
  },
]
/* End script Kolom atau header dari table */

/* Data dari table */
const data = []
/* End script Data dari table */

export default {
  components: {
    mymodal,
  },
  computed: {
    ...mapState(['settings', 'user']),
  },
  /* Data variable vue */
  data() {
    return {
      searchText: '',
      tgl1: '',
      tgl2: '',
      searchInput: null,
      searchedColumn: '',
      name: 'Tabletabungan',
      visible: false,
      statusperkawinan: {
        K: 'Kawin',
        B: 'Belum Kawin',
        D: 'Duda',
        J: 'Janda',
      },
      jenisidentitas: {
        1: 'KTP',
        2: 'SIM',
        3: 'Pasport',
      },
      yatidak: {
        0: 'Tidak',
        1: 'Ya',
      },
      perorangan: {
        1: 'Perorangan',
        2: 'Non-Perorangan',
      },
      allMaster: [],
      masterstatuskawin: [],
      masterkelamin: [],
      data,
      columns,
      datenow: '',
      componentKey: 0,
    }
  },
  /* End Data variable vue */
  mounted: function () {
    const date = moment().format('YYYY-MM-DD') // Tanggal hari ini format 2020-12-31
    // localStorage.removeItem('tabungankuedittabungandata')
    this.tgl1 = date
    this.tgl2 = date
    this.datenow = date.toString()
    this.getAllData()
    // this.getAgamaF()
  },
  methods: {
    moment,
    printHeader(record) {
      // Create a new Document an save it in a variable
      const doc = new JSPDF()
      // doc.addImage('resources/images/logo-bpr-1-01.png',
      //   'png', 15, 25, 12, 15)
      // doc.setFont(undefined, 'bold')
      doc.setFontSize(10)
      // doc.setTextColor('#062649')
      doc.text('Rekening', 15, 25)
      doc.text('Nama', 15, 30)
      doc.text('Alamat', 15, 35)
      doc.text(record.rekening, 35, 25)
      doc.text(record.nama, 35, 30)
      doc.text(record.alamat, 35, 35)
      // doc.text('BILYET DEPOSITO', 80, 25)
      // var filename = 'Validasi'
      doc.output('dataurlnewwindow')
    },
    connector() {
      // this.$refs.myownmodal.showModal('Create', {})
      localStorage.setItem('tabungankuedittabungandata', JSON.stringify({ id: '' }))
      this.$router.push('/tabungannku/tabungan/form')
    },
    rangedofunction(date, dateString) {
      this.tgl1 = moment(dateString[0], 'DD-MM-YYYY').format('YYYY-MM-DD')
      this.tgl2 = moment(dateString[1], 'DD-MM-YYYY').format('YYYY-MM-DD')
      this.getAllData()
    },
    async getAllData(onclick = false) {
      var indexeddb = await lou.getDataFromDb()
      // console.log('indexeddb', indexeddb)
      // console.log('this.settings.whatapp', this.settings.whatapp)
      var allmaster = {}
      var masterIndex = indexeddb.findIndex(
        (x) =>
          x.mykey ===
          'allmaster' +
            this.settings.whatapp.appName.toLowerCase() +
            this.settings.whatapp.id,
      )
      // console.log('masterIndex', indexeddb[masterIndex])
      if (masterIndex < 0) {
        var res = await lou.customUrlGet2('master', false, true)
        var master = {
          mykey:
            'allmaster' +
            this.settings.whatapp.appName.toLowerCase() +
            this.settings.whatapp.id,
          data: JSON.stringify(res.data),
        }
        await lou.addDataToDb(master)
        var nindexeddb = await lou.getDataFromDb()
        var targetIndex = nindexeddb.findIndex(
          (x) =>
            x.mykey ===
            'allmaster' +
              this.settings.whatapp.appName.toLowerCase() +
              this.settings.whatapp.id,
        )
        // console.log('nindexeddb', nindexeddb[targetIndex])
        allmaster = JSON.parse(nindexeddb[targetIndex].data)
      } else {
        allmaster = JSON.parse(indexeddb[masterIndex].data)
      }
      var cabanglist = []
      for (let u = 0; u < this.user.cabangList.length; u++) {
        const element = this.user.cabangList[u]
        // console.log('element', element)
        cabanglist.push({ kode: element.kode, keterangan: element.kode })
      }
      // console.log('indexeddb', indexeddb)
      this.allMaster = allmaster
      // console.log('this.allMaster', this.allMaster)
      var response = await lou.customUrlGet2('tabungan')
      if (response) {
        this.data = response.data
        if (onclick) {
          lou.shownotif('Refresh', 'Data telah di refresh.')
        }
      }
      // console.log('this.data', this.data)
    },
    sendToEdit(edata) {
      // this.$refs.myownmodal.showModal('Update', edata)
      // var ndata = {}
      // for (let i = 0; i < this.columns.length; i++) {
      //   const element = this.columns[i].dataIndex
      //   ndata[element.toString().toLowerCase()] = edata[element]
      // }
      // console.log('edata', edata)
      localStorage.setItem('tabungankuedittabungandata', JSON.stringify(edata))
      this.$router.push('/tabungannku/tabungan/form')
    },
    formatDate(value) {
      return moment(value).format('DD-MM-YYYY')
    },
    showDeleteConfirm(deldata) {
      Modal.confirm({
        title: 'Are you sure delete this data?',
        content: "You can't prevent this!!! ",
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          var response = await lou.customUrlDelete2('tabungan/' + deldata.id)
          if (response) {
            this.getAllData()
          }
        },
        onCancel: () => {
          console.log('Cancel')
        },
      })
    },
    findRelationData(data, mastername, key, datakey, label) {
      var target = this[mastername].findIndex(x => x[key] === data[datakey])
      // console.log('data', data)
      // console.log('mastername', mastername)
      // console.log('key', key)
      // console.log('target', target)
      // console.log('this.allMaster[mastername][target]', this.allMaster[mastername][target])
      // console.log('this[mastername][target]', this[mastername][target])
      return this[mastername][target][label]
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },

    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
  },
}
</script>

<style scoped>
.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
</style>
