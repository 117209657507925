<template>
  <div>
    <a-modal
      v-model="visible"
      :title="'Form tabungan'"
      :width="600"
      :dialogStyle="{ top: '10px' }"
    >
      <template slot="footer">
        <a-button class="btn btn-outline-danger" @click="handleCancel">
          Cancel
        </a-button>
        <a-button
          class="btn btn-outline-primary"
          :loading="loading"
          @click="submit()"
        >
          Simpan
        </a-button>
      </template>
      <div class="row">
        <div class="col-sm-3 col-md-3 col-lg-3 mb-2">
          <label class="mt-1">Tanggal</label>
        </div>
        <div class="col-lg-9 col-md-9 col-sm-9 mb-2">
          <a-date-picker
            style="width: 130px;"
            v-model="input.tgl"
            :allowClear="false"
            format="DD-MM-YYYY"
          />
        </div>
        <div class="col-sm-3 col-md-3 col-lg-3 mb-2">
          <label class="mt-1">No. Register</label>
        </div>
        <div class="col-lg-9 col-md-9 col-sm-9 mb-2">
          <a-select
            show-search
            option-filter-prop="children"
            :filter-option="filterOption"
            v-model="input.register_id"
            style="width: 100%;"
            @change="registeridchanged"
          >
            <a-select-option v-for="(data, index) in masterregisterNasabah" :value="data.id" :key="index">{{ data.kode + ' - ' + data.nama }}</a-select-option>
          </a-select>
        </div>
        <div class="col-sm-3 col-md-3 col-lg-3 mb-2">
          <label class="mt-1">Alamat Nasabah</label>
        </div>
        <div class="col-lg-9 col-md-9 col-sm-9 mb-2">
          <a-input placeholder="Alamat Nasabah" v-model="maskInput.alamatnasabah" :readOnly="true"></a-input>
        </div>
        <div class="col-sm-3 col-md-3 col-lg-3 mb-2">
          <label class="mt-1">Telepon</label>
        </div>
        <div class="col-lg-9 col-md-9 col-sm-9 mb-2">
          <a-input placeholder="Telepon" v-model="maskInput.telepon" :readOnly="true"></a-input>
        </div>
        <div class="col-sm-3 col-md-3 col-lg-3 mb-2">
          <label class="mt-1">Pekerjaan</label>
        </div>
        <div class="col-lg-9 col-md-9 col-sm-9 mb-2">
          <a-input placeholder="Pekerjaan" v-model="maskInput.pekerjaan" :readOnly="true"></a-input>
        </div>
        <div class="col-sm-3 col-md-3 col-lg-3 mb-2">
          <label class="mt-1">AO</label>
        </div>
        <div class="col-lg-9 col-md-9 col-sm-9 mb-2">
          <a-input placeholder="Masukan AO" v-model="input.ao"></a-input>
        </div>
        <div class="col-sm-3 col-md-3 col-lg-3 mb-2">
          <label class="mt-1">Rekening</label>
        </div>
        <div class="col-lg-9 col-md-9 col-sm-9 mb-2">
          <a-select
            show-search
            option-filter-prop="children"
            :filter-option="filterOption"
            v-model="input.rekening"
            style="width: 100%;"
          >
            <a-select-option v-for="(data, index) in masterregisterNasabah" :value="data.kode" :key="index">{{ data.kode + ' - ' + data.nama }}</a-select-option>
          </a-select>
        </div>
        <div class="col-sm-3 col-md-3 col-lg-3 mb-2">
          <label class="mt-1">No Seri</label>
        </div>
        <div class="col-lg-9 col-md-9 col-sm-9 mb-2">
          <a-input
            placeholder="Masukan No Seri"
            v-model="input.noseri"
          ></a-input>
        </div>
        <div class="col-sm-3 col-md-3 col-lg-3 mb-2">
          <label class="mt-1">Gol Tabungan</label>
        </div>
        <div class="col-lg-9 col-md-9 col-sm-9 mb-2">
          <a-select
            show-search
            option-filter-prop="children"
            :filter-option="filterOption"
            v-model="input.golongantabungan"
            style="width: 100%;"
            @change="golonganidchanged"
          >
            <a-select-option v-for="(data, index) in mastergolonganTabungan" :value="data.kode" :key="index">{{ data.kode +' - '+ data.keterangan}}</a-select-option>
          </a-select>
        </div>
        <div class="col-sm-3 col-md-3 col-lg-3 mb-2">
          <label class="mt-1">ZIS</label>
        </div>
        <div class="col-lg-9 col-md-9 col-sm-9 mb-2">
          <a-input placeholder="Masukan ZIS" v-model="input.zis"></a-input>
        </div>
        <div class="col-sm-3 col-md-3 col-lg-3 mb-2">
          <label class="mt-1">Ahli Waris</label>
        </div>
        <div class="col-lg-9 col-md-9 col-sm-9 mb-2">
          <a-input
            placeholder="Masukan Ahli Waris"
            v-model="input.ahliwaris"
          ></a-input>
        </div>
        <div class="col-sm-3 col-md-3 col-lg-3 mb-2">
          <label class="mt-1">Baris Terakhir</label>
        </div>
        <div class="col-lg-9 col-md-9 col-sm-9 mb-2">
          <a-input
            placeholder="Masukan Baris Terakhir"
            v-model="input.baristerakhir"
          ></a-input>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import moment from 'moment'
import * as lou from '@/services/data/lou'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['settings', 'user']),
  },
  data() {
    return {
      action: 'Create',
      editdata: [],
      maskInput: {
        cabang: '01',
        rerekening: '',
        alamatnasabah: '',
        telepon: '',
        pekerjaan: '',
      },
      input: {
        rekening: '',
        tgl: moment(),
        golongantabungan: '',
        golongan_id: '',
        register: '',
        register_id: '',
        zis: 0,
        ao: '',
        ahliwaris: '',
        noseri: '',
        baristerakhir: 0,
      },
      mastergolonganTabungan: [],
      backupmastergolonganTabungan: [],
      masterregisterNasabah: [],
      backupmasterregisterNasabah: [],
      visible: false,
      loading: false,
      datenow: '',
    }
  },
  created() {
    this.datenow = lou.datenow()
  },
  methods: {
    moment,
    registeridchanged(e) {
      var target = this.masterregisterNasabah.findIndex(x => x.id === e)
      if (target !== -1) {
        var data = this.masterregisterNasabah[target]
        this.input.register_id = data.id
        this.input.register = data.nama
        this.maskInput.alamatnasabah = data.alamat
        this.maskInput.telepon = data.hp
        this.maskInput.pekerjaan = data.pekerjaan
      }
    },
    golonganidchanged(e) {
      var target = this.mastergolonganTabungan.findIndex(x => x.kode === e)
      if (target !== -1) {
        this.input.golongan_id = this.mastergolonganTabungan[target].id
        this.input.golongantabungan = this.mastergolonganTabungan[target].kode
      }
    },
    async getMaster() {
      this.getMasterRegister()
      var response = await lou.customUrlGet2('golongantabungan')
      if (response) {
        this.mastergolonganTabungan = this.$g.clone(response.data)
        this.backupmastergolonganTabungan = this.$g.clone(response.data)
        // console.log('this.backupmasterKota', this.backupmasterKota)
      }
    },
    async getMasterRegister() {
      var response = await lou.customUrlGet2('registernasabah')
      if (response) {
        this.masterregisterNasabah = this.$g.clone(response.data)
        this.backupmasterregisterNasabah = this.$g.clone(response.data)
        // console.log('this.masterregisterNasabah', this.masterregisterNasabah)
      }
    },
    maskInputChangedRekening() {
      this.input.rekening = this.maskInput.cabang + this.maskInput.rerekening
    },
    async submit() {
      this.loading = true
      var fd = this.$g.clone(this.input)
      // console.log('fd.tgl', fd.tgl)
      fd.tgl =
        this.input.tgl !== null ? this.input.tgl.format('YYYY-MM-DD') : null
      var res = false
      if (this.action === 'Create') {
        res = await lou.customUrlPost2('tabungan', fd)
      } else {
        fd.id = this.editdata.id
        res = await lou.customUrlPut2('tabungan', fd)
      }
      if (res) {
        this.handleCancel()
        this.$parent.getAllData()
      }
      this.loading = false
      // console.log('this.$parent', this.$parent)
    },
    async showModal(action, data) {
      // console.log('action', action)
      // console.log('data', data)
      this.maskInput = {
        cabang: '01',
        rerekening: '',
        alamatnasabah: '',
        telepon: '',
        pekerjaan: '',
      }
      this.getMaster()
      this.visible = true
      this.editdata = data
      if (action !== 'Create') {
        this.input = this.$g.clone(data)
        setTimeout(() => {
          this.registeridchanged(data.register_id)
          this.golonganidchanged(data.golongan_id)
        }, 500)
      }
      this.action = action
      console.log('this.input', this.input)
    },
    handleCancel() {
      this.visible = false
      this.cleanForm()
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    cleanForm() {
      this.action = 'Create'
      this.editdata = []
      this.input = {
        rekening: '',
        tgl: moment(),
        golongantabungan: '',
        golongan_id: 0,
        register: '',
        register_id: 0,
        zis: 0,
        ao: '',
        ahliwaris: '',
        noseri: '',
        baristerakhir: 0,
      }
      this.maskInput = {
        cabang: '01',
        rerekening: '',
        alamatnasabah: '',
        telepon: '',
        pekerjaan: '',
      }
      this.visible = false
      this.loading = false
      this.datenow = ''
    },
  },
}
</script>

<style></style>
